<template>
	<div>
		<div class="search">
			<el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-position="left"
				:style="{ height: scrollerHeight }">
				<div class="box">
					<el-form-item :label="td('订单编号')" prop="orderNo" class="item1">
						<el-input v-model="queryParams.orderNo" :placeholder="td('请输入订单编号')" clearable size="small"
							@keyup.enter.native="handleQuery" />
					</el-form-item>
					<el-form-item :label="td('运单编号')" prop="waybillNo" class="item2">
						<el-input v-model="queryParams.waybillNo" :placeholder="td('请输入运单编号')" clearable size="small"
							@keyup.enter.native="handleQuery" />
					</el-form-item>
					<el-form-item :label="td('订单状态')" prop="orderState" class="item3">
						<el-select v-model="queryParams.orderState" :placeholder="td('订单状态')" clearable>
							<el-option v-for="dict in tf('ERP_STATE')" :key="dict.detailId" :label="dict.langTranslate"
								:value="dict.detailId" />
						</el-select>
					</el-form-item>
					<el-form-item :label="td('订单来源')" prop="source" class="item4">
						<el-select v-model="queryParams.source" :placeholder="td('订单来源')" clearable>
							<el-option v-for="dict in tf('ERP_ORDER_SOURCE')" :key="dict.detailId"
								:label="dict.langTranslate" :value="dict.detailId" />
						</el-select>
					</el-form-item>
					<el-form-item v-show="showSearchs" class="ItemShow" style="margin: 24px 0 0 0">
						<template>
							<el-form-item :label="td('创建日期')">
								<el-date-picker v-model="dateRange" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
									:default-time="['00:00:00', '23:59:59']" range-separator="~"
									:start-placeholder="td('开始日期')" :end-placeholder="td('结束日期')" size="small">
								</el-date-picker>
							</el-form-item>
							<el-form-item :label="td('SKU')" prop="skuCode">
								<el-input v-model="queryParams.skuCode" :placeholder="td('请输入SKU')" clearable
									size="small" @keyup.enter.native="handleQuery" />
							</el-form-item>
							<el-form-item :label="td('店铺')" prop="shopId">
								<el-select v-model="queryParams.shopId" :placeholder="td('请选择店铺')" clearable
									size="small" class="itemShop">
									<el-option v-for="dict in ShopList" :key="dict.shopId" :label="dict.name"
										:value="dict.shopId" size="mini" />
								</el-select>
							</el-form-item>
						</template>
					</el-form-item>


				</div>

				<div class="btns">
					<el-link class="btn11" type="primary" @click="toogleExpands"><i :class="icon"></i>{{ td(font) }}
					</el-link>
					<el-button class="btn22" type="primary" size="small" @click="handleQuery">{{ td("搜索") }}</el-button>
					<el-button class="btn33" type="primary" plain size="small" @click="resetQuery">{{ td("重置") }}
					</el-button>
				</div>
			</el-form>
		</div>

		<div class="threeBtns">
			<el-button class="btn1" icon="el-icon-upload2" type="primary" size="small" @click="handleImport">
				{{ td("导入") }}
			</el-button>
			<el-button class="btn1" style="margin-left: 16px;" icon="el-icon-download" type="primary" size="small" @click="handleexport">
				{{ td("导出") }}
			</el-button>
			<el-button class="btn1" style="margin-left: 16px;" type="primary" size="small" @click="handleAddOrder">
				{{ td("手工新增") }}
			</el-button>
			<el-button class="btn2" icon="el-icon-refresh" type="primary" plain size="small" style="float: right"
				v-loading="fullscreenLoading" element-loading-spinner="el-icon-loading" :element-loading-text="td('加载中')"
				@click="handlSynchronization">{{ td("同步订单") }}</el-button>
			<el-button class="btn3" icon="el-icon-document" type="primary" size="small"
				style="float: right; margin-right: 16px" @click="handlBatchSynchronization">{{ td("批量审核") }}</el-button>
		</div>

		<!-- 下表的数据源，分页使用 -->

		<el-table ref="table" :data="orderList" tooltip-effect="dark" row-key="orderId" max-height="550"
			@selection-change="handleSelectionChange" :row-style="{ height: '80px' }"
			:tree-props="{ children: 'children', hasChildren: 'hasChSildren' }"
			:header-cell-style="{ background: '#F9FCFF', color: '#323233','text-align':'center'}" class="firstTable">
			<el-table-column type="selection" align="center" />
			<el-table-column align="center" prop="orderNo" :label="td('订单编号')" width="140px">
				<template slot-scope="scope">
					<el-link type="primary" @click="toogleExpand(scope.row)">
						{{ scope.row.orderNo }}
					</el-link>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="waybillNo" :label="td('运单编号')" width="150px" />
			<!--      <el-table-column-->
			<!--              prop="sellerAccount"-->
			<!--              :label="td('卖家账号')"-->
			<!--              :show-overflow-tooltip="true"-->
			<!--      />-->
			<!-- <el-table-column
              prop="consignee"
              :label="td('收件人')"
              :show-overflow-tooltip="true"
      /> -->
			<el-table-column align="center" prop="platformState" :label="td('平台状态')" width="80px" />
			<el-table-column align="center" :label="td('订单状态')" width="80px">
				<template slot-scope="scope">{{
          tfp("ERP_STATE", scope.row.orderState)
          }}</template>
			</el-table-column>
			<el-table-column align="center" :label="td('订单来源')" width="80px">
				<template slot-scope="scope">
					{{tfp("ERP_ORDER_SOURCE", scope.row.source)}}
				</template>
			</el-table-column>
			<el-table-column align="center" prop="shopId" :label="td('店铺')" width="120px">
				<template slot-scope="scope">
					{{ showShopType(scope.row.shopId)}}
				</template>
			</el-table-column>
			<el-table-column align="center" prop="checkTime" :label="td('审核时间')" width="160" />
			<el-table-column align="center" :label="td('仓库')" width="160px">
				<template slot-scope="scope">
					<el-select v-model="scope.row.depotId" :placeholder="td('请选择仓库')" clearable
						:disabled="(scope.row.orderState!='10601'||((scope.row.source=='10619' && scope.row.platformState !='ready_to_ship')||(scope.row.source=='10618'&&scope.row.platformState !='PROCESSED')&&(scope.row.source!='10991')))?true:false" size="small" value-key="id">
						<el-option v-for="item in sonList" :key="item.id" :label="td(item.depotName)" :value="item.id" />
					</el-select>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="logisticName" :label="td('物流公司')" width="100px" />
			<el-table-column align="center" prop="remark" :label="td('备注')" width="100px">
				<template slot-scope="scope">
					<div v-show="scope.row.remark!='SKU未同步'&&scope.row.remark!='库存不足'?false:true"
						:style="{'color': 'rgb(255 0 5)'}">{{scope.row.remark}}</div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="createTime" :label="td('创建时间')" width="160px" />
			<!-- <el-table-column :label="td('仓库')" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ td(showDepotList(scope.row.depotId)) }}
        </template>
      </el-table-column> -->

			<el-table-column :label="td('操作')" fixed="right" width="80">
				<template slot-scope="scope">
					<el-button size="mini" type="text" icon="el-icon-folder-checked" @click="heyan(scope.row)"
						:disabled="(scope.row.orderState!='10601'||((scope.row.source=='10619' && scope.row.platformState !='ready_to_ship')||(scope.row.source=='10618'&&scope.row.platformState !='PROCESSED')&&(scope.row.source!='10991')))?true:false">
						{{ td("审核") }}
					</el-button>
					<el-button size="mini" type="text" icon="el-icon-close" @click="quxiao(scope.row)"
						:disabled="(scope.row.orderState!='10601'||((scope.row.source=='10619' && scope.row.platformState !='ready_to_ship')||(scope.row.source=='10618'&&scope.row.platformState !='PROCESSED')&&(scope.row.source!='10991')))?true:false">
						{{ td("取消") }}
					</el-button>
				</template>
			</el-table-column>
			<!-- 子列表 -->
			<el-table-column type="expand" width="1">
				<template slot-scope="scope">
					<el-table :data="allotDetailMap.get(scope.row.orderId)" :key="detailTableKey" style="margin: 0 auto"
						row-key="detailId" border>
						<el-table-column align="center" :label="td('品名')" prop="reportNameCn" />
						<el-table-column align="center" :label="td('SellerSku')" prop="sellerSku" />
						<el-table-column align="center" :label="td('云仓SKU')" prop="depotSku" />
						<el-table-column align="center" :label="td('ShopSKU')" prop="shopSKu" />
						<el-table-column align="center" :label="td('SKU CODE')" prop="skuCode" />
						<el-table-column align="center" :label="td('图片')">
							<template slot-scope="scope"><img :src="scope.row.goodsUrl"
									style="width: 50px; height: 50px" /></template>
						</el-table-column>
						<el-table-column align="center" :label="td('数量')" prop="reportNumber" />
						<el-table-column align="center" :label="td('规格型号')" prop="model" />
						<!-- <el-table-column :label="td('重量')" prop="weight" /> -->
						<el-table-column align="center"  :label="td('总价')" prop="totalPrice" />
						<!-- <el-table-column :label="td('币别')" prop="reportCurrency" /> -->
					</el-table>
				</template>
			</el-table-column>
		</el-table>

		<!-- 导入按钮 -->
		<el-dialog :title="td('订单导入')" :visible.sync="upload.open" width="400px" append-to-body>
			<el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers"
				:action="upload.url" :disabled="upload.isUploading"
				:data="{shopId:upload.shopId,outOrderType:upload.outOrderType}"
				:on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag>
				<i class="el-icon-upload"></i>
				<div class="el-upload__text">
					{{ td("将文件拖到此处，或") }}<em>{{ td("点击上传") }}</em>
				</div>
				<div class="el-upload__tip text-center" slot="tip">
					<!-- <div class="el-upload__tip" slot="tip">
            <el-checkbox v-model="upload.updateSupport" />
            {{ td("是否更新已经存在的用户数据") }}
          </div> -->
					<span>{{ td("仅允许导入xls、xlsx格式文件。") }}</span>
					<el-link type="primary" :underline="false" style="font-size: 12px; vertical-align: baseline"
						@click="importTemplate">{{ td("下载模板") }}</el-link>
				</div>
			</el-upload>
			<div style="margin-top: 10px;">
				<el-select v-model="upload.shopId" :placeholder="td('请选择店铺')" clearable size="small">
					<el-option v-for="dict in ShopList" :key="dict.shopId" :label="dict.name" :value="dict.shopId"
						size="mini" />
				</el-select>
			</div>
			<div style="margin-top: 10px;">
				<el-select v-model="upload.outOrderType" :placeholder="td('请选择出库类型')" clearable size="small">
					<el-option v-for="dict in tf('ERP_OUT_ORDER_TYPE')" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId"
						size="mini" />
				</el-select>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="upload.open = false" style="margin-right: 15px">{{
          td("取 消")
          }}</el-button>
				<el-button type="primary" @click="submitFileForm">{{
          td("确 定")
          }}</el-button>
			</div>
		</el-dialog>

		<!-- 批量审核 -->
		<el-dialog :title="td('请选择发货仓')" :visible.sync="handlCheck" @close="handlClose" width="70%"
			:append-to-body="true" :close-on-click-modal="false">
			<!-- <span style="margin-right: 20px">{{ td("选择仓库") }}</span> -->
			<el-select v-model="depotNames" @change="getHouse" :placeholder="td('请选择仓库')" clearable>
				<el-option v-for="item in sonList" :key="item.id" :label="td(item.depotName)" :value="item.id" />
			</el-select>
			<!-- <span style="display: block;text-align: center;margin: 20px 0;">您所选中的订单中，根据仓库实际库存数量和截至发货日期累急程度筛选后:</span> -->
			<el-row>
				<el-col :span="11">
					<span style="display: block;text-align: center;margin: 8px 0;color:red">{{td('可审核订单')}}:
						{{ExamineList.length}}</span>
					<el-table :data="ExamineList" style="width: 100%;padding：0"
						@selection-change="handleSelectionOrderIdChange"
						:header-cell-style="{ background: '#F9FCFF', color: '#323233' }">
						<el-table-column type="selection" align="center" />
						<el-table-column prop="orderNo" :label="td('订单号')" width="width" />
						<el-table-column prop="wayBillNo" :label="td('面单号')" width="width" />
						<el-table-column prop="createTime" :label="td('日期')" width="width" />
					</el-table>
				</el-col>
				<el-col :span="11" :offset="2">
					<span style="display: block;text-align: center;margin: 8px 0;color:red">{{td('缺货订单')}}:
						{{subList.length}}</span>
					<el-table :data="subList" style="width: 100%;padding：0"
						:header-cell-style="{ background: '#F9FCFF', color: '#323233' }">
						<el-table-column prop="orderNo" :label="td('订单号')" width="width" />
						<el-table-column prop="waybillNo" :label="td('面单号')" width="width" />
						<el-table-column prop="createTime" :label="td('日期')" width="width" />
					</el-table>
				</el-col>
			</el-row>

			<div slot="footer" class="dialog-footer">
				<el-button @click="handquxiao" style="margin-right: 15px">{{
          td("取 消")
          }}</el-button>
				<el-button type="primary" @click="submits">{{ td("确 定") }}</el-button>
			</div>
		</el-dialog>

		<el-dialog :title="td('批量审核情况')" :visible.sync="dialogTableVisible">
			<div v-for="(test,index) in auditList" :key="index">
				{{test}}
			</div>
		</el-dialog>

		<!-- 手工新增 -->
		<el-dialog :title="td('新增出库单')" :visible.sync="addOrderVisible" @close="handlCloseAddOrder" width="1200px"
			:append-to-body="true" :close-on-click-modal="false">
			<h3>{{td("订单信息 ")}}</h3>
			<el-form :model="orderForm" ref="orderForm" :inline="true" label-position="top" label-width="120px">
				<el-form-item :label="td('店铺')" prop="shopId"  :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
					<el-select v-model="orderForm.shopId" :placeholder="td('请选择店铺')" clearable size="small" style="width:350px">
						<el-option v-for="dict in ShopList" :key="dict.shopId" :label="dict.name" :value="dict.shopId"
							size="mini" />
					</el-select>
				</el-form-item>
				<el-form-item :label="td('出库类型')" prop="outOrderType" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
					<el-select v-model="orderForm.outOrderType" :placeholder="td('请选择出库类型')" clearable size="small" style="width:350px">
						<el-option v-for="dict in tf('ERP_OUT_ORDER_TYPE')" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId"
						size="mini" />
					</el-select>
				</el-form-item>
				<el-form-item :label="td('销售单号')" prop="orderNo" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
					<el-input v-model="orderForm.orderNo" :placeholder="td('请输入销售单号')" clearable size="small" style="width:350px"/>
				</el-form-item>
				<!-- 5661 备货出库 5662 标准出库 5663 销毁出库 -->
				<div v-if="orderForm.outOrderType != 5663">
					<h3>{{td("收件人信息")}}</h3>
					<el-form-item :label="td('收件人')" prop="consignee" :rules="[{ required: true, message: ' ', trigger: 'blur' }]" style="margin-left: 0;">
						<el-input v-model="orderForm.consignee" :placeholder="td('请输入收件人')" clearable size="small" style="width:350px"/>
					</el-form-item>
					<el-form-item :label="td('收件电话')" prop="consigneePhone" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
						<el-input v-model="orderForm.consigneePhone" :placeholder="td('请输入收件电话')" clearable size="small" style="width:350px"/>
					</el-form-item>
					<el-form-item :label="td('国家/地区')" prop="consigneeCountry" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
						<el-select v-model="orderForm.consigneeCountry" :placeholder="td('请选择国家/地区')" clearable size="small" style="width:350px">
							<el-option v-for="dict in tf('COUNTRY')" :key="dict.detailId" :label="dict.langTranslate" :value="dict.detailId"
							size="mini" />
						</el-select>
					</el-form-item>
					<el-form-item :label="td('省份')" style="margin-left: 0;">
						<el-input v-model="orderForm.consigneeProvince" :placeholder="td('请输入省份')" clearable size="small" style="width:350px"/>
					</el-form-item>
					<el-form-item :label="td('城市')" prop="consigneeCity" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
						<el-input v-model="orderForm.consigneeCity" :placeholder="td('请输入城市')" clearable size="small" style="width:350px"/>
					</el-form-item>
					<el-form-item :label="td('邮编')" prop="consigneePoster" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
						<el-input v-model="orderForm.consigneePoster" :placeholder="td('请输入邮编')" clearable size="small" style="width:350px"/>
					</el-form-item>
					<el-form-item :label="td('区县')" style="margin-left: 0;">
						<el-input v-model="orderForm.consigneeArea" :placeholder="td('请输入区县')" clearable size="small" style="width:350px"/>
					</el-form-item>
					<el-form-item :label="td('乡镇')">
						<el-input v-model="orderForm.consigneeTown" :placeholder="td('请输入乡镇')" clearable size="small" style="width:350px"/>
					</el-form-item>
					<el-form-item :label="td('收件地址')" prop="consigneeAddress" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
						<el-input v-model="orderForm.consigneeAddress" :placeholder="td('请输入收件地址')" clearable size="small" style="width:350px"/>
					</el-form-item>
				</div>
				<div >
					<h3>{{td("商品信息")}}</h3>
					<el-table :key="tableKey" :data="orderForm.orderDetailImportData" :row-class-name="rowWmsInOrderDetailIndex"
						tooltip-effect="dark" border
						:header-cell-style="{ background: '#F9FCFF', color: '#323233' }">
						<el-table-column type="selection" width="50" align="center" />
						<el-table-column :label="td('序号')" align="center" prop="index" width="100" />
						<el-table-column :label="td('货品')" width="250" align="center">
							<template slot-scope="scope">
								<el-form-item :prop=" 'orderDetailImportData.' + scope.$index + '.sonSku' "
									:rules="[{ required: true, message: ' ', trigger: 'blur' }]">
									<el-select v-model="scope.row.sonSku" @change="chanSelect"
										:placeholder='td("请选择货品")' filterable style="width:220px">
										<el-option v-for="(item,index) in tableDataa" :key="index"
											:label="`${item.sellerSku} / ${item.goodsName}`" :value="index" />
									</el-select>
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column :label="td('规格/型号')" align="center">
							<template slot-scope="scope">
								<span>{{ getRowCon(scope.row.sonSku,'moudle') }}</span>
							</template>
						</el-table-column>
						<el-table-column :label='td("数量")' align="center">
							<template slot-scope="scope">
								<el-form-item :prop=" 'orderDetailImportData.' + scope.$index + '.reportNumber' "
									:rules="[{ required: true, message: ' ', trigger: 'blur' }]"
									 style="margin-top:20px">
									<el-input v-model="scope.row.reportNumber" />
								</el-form-item>
							</template>
						</el-table-column>
						<el-table-column :label="td('重量（g）')" align="center">
							<template slot-scope="scope">
								<span>{{ getRowCon(scope.row.sonSku,'goodsWeight') }}</span>
							</template>
						</el-table-column>
						<el-table-column :label="td('操作')" align="center">
							<template slot-scope="scope">
								<el-button size="mini" type="text" icon="el-icon-plus" @click="addSonSku(scope.row)">
								</el-button>
								<el-button size="mini" type="text" icon="el-icon-minus" @click="delSonSku(scope.$index)">
								</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div v-if="orderForm.outOrderType != 5663">
					<h3>{{td("物流信息")}}</h3>
					<el-form-item :label="td('物流公司')" prop="logisticName" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
						<el-select v-model="orderForm.logisticName" :placeholder="td('请选择物流公司')" clearable size="small" style="width:350px">
							<el-option v-for="item in logisticsLists" :key="item.id" :label="td(item.shortName)"
							:value="td(item.shortName)" />
						</el-select>
					</el-form-item>
					<el-form-item :label="td('运单编号')" prop="waybillNo" :rules="[{ required: true, message: ' ', trigger: 'blur' }]">
						<el-input v-model="orderForm.waybillNo" :placeholder="td('请输入运单编号')" clearable size="small" style="width:350px"/>
					</el-form-item>
				</div>
			</el-form>
			<span slot="footer" class="footet">
				<el-button @click="handlCloseAddOrder()" type="primary" plain>{{td('取 消')}}</el-button>
				<el-button @click="handlcomitAddOrder(form)" type="primary" style="margin-left: 10px;" :disabled="finish">{{td('确 定')}}</el-button>
			</span>
		</el-dialog>


		<pagination :total="td(total)" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
			@pagination="getOrder" />
	</div>
</template>

<script>
	import {
		getOrderList,
		getSonList,
		cancelOrder,
		getCategoryDetail,
		synchronization,
		synchronizationLazda,
		pliaoHetyeyan,
		checkOrder,
		BatchAudit,
		exportOrder,
		manualImportData
	} from "@/api/order/orderList";
	import {
		getListtt,
		getshop,
		getsons
	} from "@/api/warehouse";
	import {
		getLogistics
	} from '@/api/warehouse/delivery'
	import axios from "axios";

	import {
		getToken
	} from "@/utils/auth";

	import { download2 } from "@/utils/request"

	import {
		Throttle,
		addDateRange
	} from "@/utils/bd.js";

	export default {
		name: "orderList",
		data() {
			return {
				value1: false,
				select: "",
				value: "",
				fullscreenLoading: false,
				total: 0,
				plList: [],
				danData: [],
				depotIds: null,
				// 弹窗
				addOrderDig: false,
				importOrderDig: false,
				dialogTableVisible: false,
				taskDig: false,
				//批量核验 弹窗
				handlCheck: false,
				depotNames: null,
				//end
				//存放 订单 管理
				orderList: [],
				//存放 子订单 管理
				sonOrderList: [],
				//子表数据
				allotDetailMap: new Map(),
				//内嵌 table key
				detailTableKey: false,
				//存放 仓库选择
				sonList: [],
				//店铺列表
				ShopList: [],
				// 显示搜索条件
				showSearch: true,
				// 总条数
				total: 0,
				checkedErpSkuSon: [],
				// 查询参数
				queryParams: {
					orderNo: null,
					waybillNo: null,
					sellerAccount: null,
					orderState: null,
					source: null,
					skuCode: null,
					pageNum: 1,
					pageSize: 10,
				},
				dateRange: [],
				// 表单参数
				form: {},
				//搜索 状态查询 条件
				// changeState:'ERP_STATE',
				//搜索 订单状态
				serchState: [],
				//下拉框的值
				selectVal: null,
				upload: {
					// 是否显示弹出层
					open: false,
					// 是否禁用上传
					isUploading: false,
					// 是否更新已经存在的用户数据
					updateSupport: 0,
					// 设置上传的请求头部
					headers: {
						Authorization: "Bearer " + getToken()
					},
					// 上传的地址
					// url: "https://interface.6pl.com.cn" + "/erp/api/order/importData/",
					url: this.baseURL + "/erp/api/order/importData/",
				},
				showSearchs: false,
				font: "更多",
				icon: "el-icon-arrow-down",
				OutstockList: [],
				ExamineList: [],
				subList: [],
				auditList: [],
				addOrderVisible:false,
				orderForm:{},
				tableDataa:[],
				tableKey:1,
				finish:false,
				logisticsLists:[]
			};
		},
		created() {
			this.getOrder();
			this.getOther();
			this.getShop();
			this.getSonlist();
			this.logisticsList();
		},
		methods: {
			getRowCon(index,key){
				if(index || index==0){
					return this.tableDataa[index][key]
				}
				return ''
			},
			chanSelect(index) {
				// console.log(index,'index');
				// this.orderForm.orderDetailImportData[params] = ;
				// for (let i = 0; i < this.orderForm.orderDetailImportData.length; i++) {
				// 	if (index == this.orderForm.orderDetailImportData[i].sonSku) {
				// 		return (
				// 			this.$message.warning(this.td('请勿重复选择！')), this.orderForm.orderDetailImportData.splice(index, 1)
				// 		)
				// 	}
				// }
			},
			addSonSku() {
				let obj = {}
				this.orderForm.orderDetailImportData.push(obj);
				this.tableKey++
				// console.log(this.tableDataa);
			},
			delSonSku(index) {
				// for (let i = 0; i < this.orderForm.orderDetailImportData.length; i++) {
				// 	if (row.index === this.orderForm.orderDetailImportData[i].index) {
						this.orderForm.orderDetailImportData.splice(index, 1);
				// 	}
				// }
			},
			logisticsList() {
				getLogistics().then(res => {
					this.logisticsLists = res.rows
				})
			},
			getSonlist() {
				getsons().then(res => {
					this.tableDataa = res.rows;
				})
			},
			rowWmsInOrderDetailIndex({
				row,
				rowIndex
			}) {
				row.index = rowIndex + 1;
			},
			// 手工新增
			handleAddOrder(){
				this.orderForm = {
					orderDetailImportData:[{}]
				}
				this.addOrderVisible = true;
			},
			handlcomitAddOrder(){
				// orderForm
				this.$refs['orderForm'].validate((valid) => {
					if (valid) {
						if (!this.orderForm.orderDetailImportData.length) {
							this.$message.warning(this.td('商品信息不能为空！'));
							return;
						}
						this.finish = true;
						let orderDetailImportData = this.orderForm.orderDetailImportData.map(item=>({sonSku:this.tableDataa[item.sonSku].sonSku,reportNumber:item.reportNumber}));
						let form = {
							...this.orderForm,
							orderDetailImportData
						}
						manualImportData(form).then(res=>{
							if (res.code === 200) {
								this.$message.success(this.td("新增成功"));
								this.getOrder()
								this.orderForm = {};
								this.addOrderVisible = false
								this.finish = false
							}else{
								this.finish = false
								return this.$message.error(this.td(res.msg));
							}
						}).catch(()=>{
							this.finish = false
						})
					} else {
						console.log('error submit!!');
						this.finish = false
						return false;
				}
				});
			},
			handlCloseAddOrder(){
				this.$refs.orderForm.resetFields();
				this.addOrderVisible = false;
			},
			//获取订单表数据
			getOrder() {
				getOrderList(addDateRange(this.queryParams, this.dateRange)).then(
					(res) => {
						this.orderList = res.rows;
						this.total = res.total;
					}
				);
			},
			getOther() {
				//获取仓库
				getListtt().then((res) => {
					this.sonList = res.data;
				});
			},
			getShop() { //获取店铺
				getshop().then((res) => {
					console.log(res,'resres');
					this.ShopList = res.rows;
				})
			},
			getAllotDetail(row) {
				//根据 allotId 获取子表
				getSonList(row).then((res) => {
					// 使用 map 结构的方式保存子表
					this.allotDetailMap.set(row, res.rows);
					this.detailTableKey = !this.detailTableKey;
					this.reset();
				});
			},
			//显示子
			toogleExpand(val) {
				this.getAllotDetail(val.orderId);
				let $table = this.$refs.table;
				$table.toggleRowExpansion(val);
			},
			// 表单重置
			reset() {
				this.form = {
					orderNo: null,
					waybillNo: null,
					sellerAccount: null,
					orderState: null,
					source: null,
					skuCode: null,
					orderId: null,
				};
				this.resetForm("form");
			},
			/** 搜索按钮操作 */
			handleQuery() {
				this.queryParams.pageNum = 1;
				this.getOrder();
			},
			/** 重置按钮操作 */
			resetQuery() {
				this.resetForm("queryForm");
				this.dateRange = [];
				this.handleQuery();
			},

			//核验
			heyan(data) {
				var mydata = [data];
				if (data.depotId == null) {
					return this.$message.warning(this.td("请选择仓库"));
				}
				console.log(data);
				let obj = {};
				obj.depotId = data.depotId;
				obj.orderIds = new Array;
				obj.orderIds.push(data.orderId)
				BatchAudit(obj).then((res) => {
						if (res.code !== 200) {
							return this.$message.error(this.td(res.data[0]));
						}
						this.$message.success(this.td(res.data[0]));
						this.getOrder();
					})
					.catch(() => {
						this.getOrder();
					});
			},
			//取消按钮
			async quxiao(data) {
				const confirmResult = await this.$confirm(
					this.td("此操作将取消订单，是否继续?"),
					this.td("提示"), {
						confirmButtonText: this.td("确定"),
						cancelButtonText: this.td("取消"),
						type: "warning",
					}
				).catch((err) => err);
				if (confirmResult !== "confirm")
					return this.$message.info(this.td("已取消"));
				data.orderState = '10603';
				cancelOrder(data).then((res) => {
					if (res.code !== 200) {
						return this.$message.error(this.td("取消失败"));
					}
					this.$message.success(this.td("取消成功"));
					this.getOrder();
				});
			},

			//同步订单
			handlSynchronization: Throttle(function() {
				(this.fullscreenLoading = true),
				axios
					.all([synchronizationLazda(), synchronization()])
					.then(
						axios.spread(() => {
							this.$message.success(this.td("同步成功"));
							this.fullscreenLoading = false;
							this.getOrder();
						})
					)
					.catch(() => {
						this.$message.success(this.td("同步成功"));
						this.fullscreenLoading = false;
						this.getOrder();
					});
			}, 3000),

			//多选
			handleSelectionChange(selection) {
				this.plList = selection;
			},
			getHouse(val) {
				for (let i = 0; i < this.plList.length; i++) {
					this.plList[i].depotId = val;
				}
			},
			handlBatchSynchronization() {
				if (this.plList == '') {
					return this.$message.warning(this.td("请选择批量数据"));
				}
				for (let i = 0; i < this.plList.length; i++) {
					if (this.plList[i].orderState !== "10601") {
						return this.$message.warning(this.td("选中的数据中，部分已操作，请勿重复操作!"));
					}
				}
				let orderIdArr = new Array;
				for (let i = 0; i < this.plList.length; i++) {
					orderIdArr.push(this.plList[i].orderId);
				}
				checkOrder(orderIdArr).then(res => {
					let OrderUtil = this.OrderForm(this.plList, res);
				})
				this.handlCheck = true;
			},
			OrderForm(data, val) {
				let order = -1;
				for (let i = 0; i < data.length; i++) { //选中数组
					let Oindex = this.findOrder(data[i].orderId, val);
					if (Oindex == -1) {
						this.ExamineList.push(data[i]);
					} else {
						this.subList.push(data[i]);
					}
				}
			},
			findOrder(orderId, val) {
				let index = -1;
				for (let x = 0; x < val.length; x++) { //查询数组
					if (orderId == val[x].orderId) {
						index = x;
						break;
					} else {
						continue;
					}
				}
				return index;
			},
			handleSelectionOrderIdChange(selection) {
				this.OutstockList = selection;
			},
			handquxiao() {
				this.handlCheck = false;
				this.depotNames = null;
			},
			submits() {
				if (this.depotNames == null) {
					return this.$message.warning(this.td("请选择仓库！"))
				}
				let obj = {};
				obj.orderIds = new Array;
				for (let i = 0; i < this.OutstockList.length; i++) {
					if (obj.orderIds.length == 0) {
						obj.depotId = this.depotNames;
						obj.orderIds.push(this.OutstockList[i].orderId);
					} else {
						for (let x = 0; x < obj.orderIds.length; x++) {
							if (obj.orderIds[x] != this.OutstockList[i].orderId) {
								obj.orderIds.push(this.OutstockList[i].orderId);
								if (obj.orderIds.length == this.OutstockList.length) {
									break;
								}
							}
						}
					}
				}
				BatchAudit(obj).then(res => {
					this.auditList = res.data;
					this.dialogTableVisible = true;
					this.depotNames = null
					this.handlCheck = false
					this.getOrder();
				}).catch(() => {
					this.depotNames = null
					this.handlCheck = false
				})
			},
			handlClose() {
				this.getOrder();
				this.ExamineList = [];
				this.subList = [];
				this.OutstockList = [];
				this.depotNames = null;
			},

			handleFileUploadProgress(event, file, fileList) {
				this.upload.isUploading = true;
			},
			handleFileSuccess(response, file, fileList) {
				this.upload.open = false;
				this.upload.isUploading = false;
				this.$refs.upload.clearFiles();
				this.$alert(response.msg, this.td("导入结果"), {
					dangerouslyUseHTMLString: true,
				});
				this.getOrder();
			},
			handleImport() {
				this.upload.open = true;
			},
			async handleexport(){
				let params = {};
				if (this.plList.length){
					params = { orderIdList:this.plList.map(res=>res.orderId) }
				}else{
					params = addDateRange(this.queryParams, this.dateRange);
				}
			  	download2('/erp/api/order/export',params,'订单列表.xlsx')
				
				// console.log(res,'resresres');
				// this.orderList
			},
			/** 下载模板操作 */
			importTemplate() {
				//     this.download(
				// this.baseURL + "/erp/order/importTemplate",
				//       {
				//         ...this.queryParams,
				//       },
				//       `order_${new Date().getTime()}.xlsx`
				//     );
				window.open('/excel_model/ERP导入表格.xlsx', "_blank");
			},
			// 文件上传中处理
			handleFileUploadProgress(event, file, fileList) {
				this.upload.isUploading = true;
			},
			// 文件上传成功处理
			handleFileSuccess(response, file, fileList) {
				// console.log(response)
				this.upload.open = false;
				this.upload.isUploading = false;
				this.$refs.upload.clearFiles();
				this.$alert(response.msg, this.td("导入结果"), {
					dangerouslyUseHTMLString: true,
				});
				this.getOrder();
				this.upload.shopId = null;
			},
			// 提交上传文件
			submitFileForm() {
				// this.$refs.upload.submit();
				if (this.upload.shopId == null) {
					this.$message.info(this.td("请选择店铺"))
				} else {
					// this.upload.url = "https://interface.6pl.com.cn" + "/erp/order/importData?shopId="+this.upload.shopId,
					// this.upload.url = "https://interface.6pl.com.cn" + "/erp/order/importData/"+this.upload.shopId,
					console.log(this.upload);
					this.$refs.upload.submit();
				}
			},
			toogleExpands() {
				if (this.showSearchs) {
					this.showSearchs = !this.showSearchs;
					this.icon = "el-icon-arrow-down";
					this.font = this.td("更多")
				} else {
					this.showSearchs = !this.showSearchs;
					this.font = this.td("收起")
					this.icon = "el-icon-arrow-up";
				}
			},
		},
		computed: {
			//显示仓库名字
			showDepotList() {
				return function(number) {
					for (let i = 0; i < this.sonList.length; i++) {
						if (number == this.sonList[i].id) {
							return this.sonList[i].depotName;
						}
					}
				};
			},
			scrollerHeight: function() {
				return this.showSearchs ? 160 + "px" : 80 + "px";
			},
			showShopType: function() {
				return function(number) {
					// console.log(number);
					for (let i = 0; i < this.ShopList.length; i++) {
						if (number == this.ShopList[i].shopId) {
							return this.ShopList[i].name
						}
					}
				}
			},
		},
	};
</script>

<style scoped>
	.search {
		position: relative;
		background-color: #fff;
	}

	.search .box {
		position: absolute;
		top: 20px;
	}

	.search .el-form {
		padding: 0 16px;
		color: #333333;
		margin-right: 0;
		border-bottom: 16px solid #F1F3FF;
	}

	.search .el-form-item {
		margin-bottom: 0;
		margin-right: 0;
	}

	.search ::v-deep .el-form-item__label {
		margin-right: 24px;
		padding: 0;
	}

	.search ::v-deep .el-input__inner {
		width: 220px;
	}

	.search ::v-deep .el-input__inner {
		height: 32px;
	}

	.el-form-item:not(:first-child) {
		margin-left: 32px;
	}

	.btns {
		position: absolute;
		top: 24px;
		right: 16px;
	}

	.btns .el-button {
		width: 74px;
		height: 32px;
	}

	.btns .btn11,
	.btn22 {
		margin-right: 16px;
	}

	.input-with-select ::v-deep .el-input-group__prepend {
		background-color: #fff;
	}

	/* .search .item3,.item4 ::v-deep .el-input__icon {
    line-height: 40px;
  }

  .search .el-date-picker ::v-deep .el-input__icon {
    line-height: 31px!important;
  } */

	::v-deep .el-button+.el-button {
		margin: 0;
	}

	::v-deep .el-loading-spinner {
		margin-top: -7px;
		margin-left: -30px;
	}

	::v-deep .el-loading-spinner .el-loading-text {
		margin: -12px 0 0 60px;
	}

	.threeBtns {
		background-color: #fff;
		padding: 24px 16px;
	}

	.btn1 {
		width: 80px;
		height: 32px;
	}

	.btn2 {
		width: 108px;
		height: 32px;
	}

	.btn3 {
		width: 108px;
		height: 32px;
	}

	.firstTable {
		padding: 0 16px;
	}

	/* 110% */
	@media screen and (max-width: 1800px) {
		.search ::v-deep .el-input__inner {
			width: 200px;
		}

		.search ::v-deep .el-form-item__label {
			margin-right: 12px;
		}

		.el-form-item:not(:first-child) {
			margin-left: 16px;
		}

		.btns {
			position: absolute;
			top: 24px;
			right: 0px;
		}

		.btns .btn11 {
			margin-right: 16px;
			/* transform: translate(23px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(23px, 0) scale(0.65, 0.65);
      -moz-transform: translate(23px, 0) scale(0.65, 0.65);
      -o-transform: translate(23px, 0) scale(0.65, 0.65); */
		}

		.btns .btn22 {
			margin-right: 16px;
			/* transform: translate(18px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(18px, 0) scale(0.65, 0.65);
      -moz-transform: translate(18px, 0) scale(0.65, 0.65);
      -o-transform: translate(18px, 0) scale(0.65, 0.65); */
		}

		.btns .btn33 {
			margin-right: 16px;
			/* transform: scale(0.65, 0.65);
      -webkit-transform: scale(0.65, 0.65);
      -moz-transform: scale(0.65, 0.65);
      -o-transform: scale(0.65, 0.65); */
		}

		/* .btn1 {
      transform: translate(-15px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(-15px, 0) scale(0.65, 0.65);
      -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
      -o-transform: translate(-15px, 0) scale(0.65, 0.65);
    }
    .btn2 {
      transform: translate(20px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(20px, 0) scale(0.65, 0.65);
      -moz-transform: translate(20px, 0) scale(0.65, 0.65);
      -o-transform: translate(20px, 0) scale(0.65, 0.65);
    }
    .btn3 {
      transform: translate(-15px, 0) scale(0.65, 0.65);
      -webkit-transform: translate(60px, 0) scale(0.65, 0.65);
      -moz-transform: translate(-15px, 0) scale(0.65, 0.65);
      -o-transform: translate(-15px, 0) scale(0.65, 0.65);
    } */
	}

	/* 125% */
	@media screen and (max-width: 1620px) {
		.search ::v-deep .el-input__inner {
			width: 160px;
		}

		.search ::v-deep .el-form-item__label {
			margin-right: 12px;
		}

		.el-form-item:not(:first-child) {
			margin-left: 16px;
		}

		.btns {
			position: absolute;
			top: 24px;
			right: 0px;
		}

		.btns .btn11 {
			margin-right: 0px;
			transform: translate(23px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(10px, 0) scale(0.85, 0.85);
			-moz-transform: translate(23px, 0) scale(0.85, 0.85);
			-o-transform: translate(23px, 0) scale(0.85, 0.85);
		}

		.btns .btn22 {
			margin-right: 16px;
			transform: translate(16px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(16px, 0) scale(0.85, 0.85);
			-moz-transform: translate(16px, 0) scale(0.85, 0.85);
			-o-transform: translate(16px, 0) scale(0.85, 0.85);
		}

		.btns .btn33 {
			transform: scale(0.85, 0.85);
			-webkit-transform: translate(0px, 0) scale(0.85, 0.85);
			-moz-transform: scale(0.85, 0.85);
			-o-transform: scale(0.85, 0.85);
		}

		.btn1 {
			transform: translate(-5px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(-5px, 0) scale(0.85, 0.85);
			-moz-transform: translate(-5px, 0) scale(0.85, 0.85);
			-o-transform: translate(-5px, 0) scale(0.85, 0.85);
		}

		.btn2 {
			transform: translate(20px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(7px, 0) scale(0.85, 0.85);
			-moz-transform: translate(20px, 0) scale(0.85, 0.85);
			-o-transform: translate(20px, 0) scale(0.85, 0.85);
		}

		.btn3 {
			transform: translate(28px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(28px, 0) scale(0.85, 0.85);
			-moz-transform: translate(28px, 0) scale(0.85, 0.85);
			-o-transform: translate(28px, 0) scale(0.85, 0.85);
		}
	}

	/* 150% */
	@media screen and (max-width: 1500px) {
		.search ::v-deep .el-input__inner {
			width: 160px;
		}

		.search ::v-deep .el-form-item__label {
			margin-right: 16px;
		}

		.el-form-item:not(:first-child) {
			margin-left: 0px;
		}

		.item1 {
			transform: translate(-30px, 0) scale(0.85, 0.85);
			-webkit-transform: translate(-30px, 0) scale(0.85, 0.85);
			-moz-transform: translate(-30px, 0) scale(0.85, 0.85);
			-o-transform: translate(-30px, 0) scale(0.85, 0.85);
		}

		.item2 {
			transform: translate(-67px, 0) scale(0.75, 0.75);
			-webkit-transform: translate(-67px, 0) scale(0.75, 0.75);
			-moz-transform: translate(-67px, 0) scale(0.75, 0.75);
			-o-transform: translate(-67px, 0) scale(0.75, 0.75);
		}

		.item3 {
			transform: translate(-104px, 0) scale(0.75, 0.75);
			-webkit-transform: translate(-104px, 0) scale(0.75, 0.75);
			-moz-transform: translate(-104px, 0) scale(0.75, 0.75);
			-o-transform: translate(-104px, 0) scale(0.75, 0.75);
		}

		.item4 {
			transform: translate(-143px, 0) scale(0.75, 0.75);
			-webkit-transform: translate(-143px, 0) scale(0.75, 0.75);
			-moz-transform: translate(-143px, 0) scale(0.75, 0.75);
			-o-transform: translate(-143px, 0) scale(0.75, 0.75);
		}

		.btns {
			position: absolute;
			top: 24px;
			right: 0px;
		}

		.btns .btn11 {
			margin-right: 0px;
			transform: translate(35px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(35px, 0) scale(0.65, 0.65);
			-moz-transform: translate(35px, 0) scale(0.65, 0.65);
			-o-transform: translate(35px, 0) scale(0.65, 0.65);
		}

		.btns .btn22 {
			margin-right: 0px;
			transform: translate(28px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(28px, 0) scale(0.65, 0.65);
			-moz-transform: translate(28px, 0) scale(0.65, 0.65);
			-o-transform: translate(28px, 0) scale(0.65, 0.65);
		}

		.btns .btn33 {
			transform: translate(13px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(13px, 0) scale(0.65, 0.65);
			-moz-transform: translate(13px, 0) scale(0.65, 0.65);
			-o-transform: translate(13px, 0) scale(0.65, 0.65);
		}

		.btn1 {
			transform: translate(-15px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(-15px, 0) scale(0.65, 0.65);
			-moz-transform: translate(-15px, 0) scale(0.65, 0.65);
			-o-transform: translate(-15px, 0) scale(0.65, 0.65);
		}

		.btn2 {
			transform: translate(18px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(18px, 0) scale(0.65, 0.65);
			-moz-transform: translate(18px, 0) scale(0.65, 0.65);
			-o-transform: translate(18px, 0) scale(0.65, 0.65);
		}

		.btn3 {
			transform: translate(60px, 0) scale(0.65, 0.65);
			-webkit-transform: translate(60px, 0) scale(0.65, 0.65);
			-moz-transform: translate(60px, 0) scale(0.65, 0.65);
			-o-transform: translate(60px, 0) scale(0.65, 0.65);

		}
	}

	@media screen and (min-width: 2510px) {
		.item4 {
			margin-right: 32px !important;
		}
	}

	@media screen and (min-width: 2561px) {
		.ItemShow {
			margin: 0 0 0 0px !important;
		}
	}
</style>
